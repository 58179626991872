import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import DataEncrypt from './DataEncrypt';

export const dataLink = process.env.REACT_APP_DATA;
function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  // const [isGovtEmployee, setIsGovtEmployee] = useState(false);
  const [source, setSource] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [alertMessage, setAlertMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [referralID, setReferralID] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (source === 'referral') {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [source]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const ag = urlParams.get('ag');
    if (ag) {
      Cookies.set('ag', ag, { expires: 1 });
    }

    const from = urlParams.get('from');
    if (from) {
      Cookies.set('from', from, { expires: 1 });
      if (from === 'referral') {
        setIsVisible(true);
        setSource('referral')
      }
    }
    const ref = urlParams.get('ref');
    if (ref) {
      const encryptedRef = DataEncrypt.DecryptData(ref);
      const sanitizedRef = encryptedRef.replace(/[^\w\s]/gi, ''); // Remove non-alphanumeric characters
      Cookies.set('ref', sanitizedRef, { expires: 1 });
      setReferralID(sanitizedRef);
      console.log(sanitizedRef);
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (isLoggedIn) {
      body.classList.add('isLoggedIn');
    } else {
      body.classList.remove('isLoggedIn');
    }

    const handleClick = (event) => {
      if (event.target.classList.contains('modal-close')) {
        document.querySelectorAll('input').forEach(input => input.value = '');
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false);
        document.querySelectorAll('select').forEach(select => select.selectedIndex = 0);
        setErrorMessage('');
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const agValue = Cookies.get('ag') || 'SG8JPPMR';

  const validatePassword = (password) => {
    if (password.length < 6 || password.length > 8) {
      return "Password needs to be between 6 - 8 characters.";
    }
  
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    if (!hasUpperCase || !hasLowerCase) {
      return "Password must contain both uppercase and lowercase characters.";
    }
  
    const hasNumber = /[0-9]/.test(password);
    if (!hasNumber) {
      return "Password must contain at least one number.";
    }
  
    return null;
  };
  
  const handleRegister = async () => {
    const passwordError = validatePassword(password);
    if (passwordError) {
      setErrorMessage(passwordError);
      return;
    }
    
    // if (!isGovtEmployee) {
    //   setErrorMessage("Government employees or officials are not allowed to register.");
    //   return;
    // }
    
    if (!source) {
      setErrorMessage("Please select how you heard about us.");
      return;
    }

    // Switch statement to change source value
  let sourceValue = source;
  switch (source) {
    case 'fb':
    case 'fbads':
      sourceValue = 'Facebook Ads';
      break;
    case 'instagram':
      sourceValue = 'Instagram';
      break;
    case 'twitter':
      sourceValue = 'Twitter';
      break;
    case 'tiktok':
      sourceValue = 'Tiktok';
      break;
    case 'google':
      sourceValue = 'Google Search';
      break;
    case 'googleAds':
      sourceValue = 'Google Ads';
      break;
    case 'referral':
      sourceValue = 'Friend Referral';
      break;
    case 'sms':
      sourceValue = 'SMS';
      break;
    case 'postersBanners':
      sourceValue = 'Posters/Banners';
      break;
    case 'others':
      sourceValue = 'Others';
      break;
    default:
      break;
  }
  
  setLoading(true);
  try {
    const response = await fetch(dataLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        cmd: "register",
        ag: agValue,
        name1: firstName,
        name2: lastName,
        password: password,
        phone: phoneNumber,
        dob: dob,
        domain: "sg8.casino",
        from: sourceValue,
        find1: 'referral',
        find2: referralID,
      })
    });
    
    setLoading(false); 
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      if (data.s === "s") {
        setErrorMessage('Registration successful:');
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', data.u);
        window.location.href = "/"; 
      } else {
        setErrorMessage(data.emsg || "Registration failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("There was a problem with the registration operation. Please try again.");
      setLoading(false); 
    }

  };

  const messageClass = errorMessage === 'Registration successful:' ? 'smsg' : 'emsg';

  return (
    <div>
      <div className='form-register'>
        <img className='formlogo' src='/images/SG8-logo.png' alt='SG8 Logo' />
        <label>Full Name/Bank Account</label>
        <input type="text" onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
        <label>Password</label>
        <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        <label>Phone Number</label>
        <input type="tel" onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
        <label>How did you hear about us?</label>
        <select value={source} onChange={(e) => setSource(e.target.value)}>
          <option value="">--- Select ---</option>
          <option value="fb">Facebook</option>
          <option value="fbads">Facebook Ads</option>
          <option value="instagram">Instagram</option>
          <option value="twitter">Twitter</option>
          <option value="tiktok">Tiktok</option>
          <option value="google">Google Search</option>
          <option value="googleAds">Google Ads</option>
          <option value="referral">Friend Referral</option>
          <option value="sms">SMS</option>
          <option value="postersBanners">Posters/Banners</option>
          <option value="others">Others</option>
        </select>
        {isVisible && (
          <div id='refid'>
            <label>Referral ID</label>
            <input
              type="text"
              value={referralID}
              readOnly
            />
          </div>
        )}
        {/* <label>
          <input
            type="checkbox"
            onChange={(e) => setIsGovtEmployee(e.target.checked)}
          />
          I am not a government official or employee connected directly with the operation of the Government or any of its agencies, member of the Armed Forces of the Philippines, including the Army, Navy, Air Force, or the Philippine National Police.
        </label> */}
        <button onClick={handleRegister}>Register</button>
        {errorMessage && <p className={messageClass}>{errorMessage}</p>}
      </div>
      {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
    </div>
  );
}

export default Register;