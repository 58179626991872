import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import MainSlider from "../../snippets/MainSlider";
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from "../../App";

export default function ReferralBonus() {
  const title = "Referral Bonus";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("ReferralBonus");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("ReferralBonus");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino Referral Rewards | Earn Extra Bonuses for Each Friend</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Invite your friends to SG8 Casino and earn up to 10% referral bonus. Share the excitement and enjoy extra rewards together. Refer now!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <div className="main-wrapper-promo">
        <div className="main-content-area" >
        <h2>Referral Rewards</h2>
        <p>How about this for a sweet deal - refer friends to join SG8Bet as members and we'll give you extra bonus whenever they play.</p><br/>
        <h2>For Example</h2>
        <ul>
          <li>
          Member A refer Member B to register and play SG8Bet games on a Monday.
          </li>
          <li>
          Member B deposit and play SG8Bet games from Monday to Sunday.
          </li>
          <li>Member B won a total of 10,000THB from Monday to Sunday
          <ul>
            <li>
            Monday won 1,000THB
            </li>
            <li>
            Tuesday did not play
            </li>
            <li>
            Wednesday won 2,000THB
            </li>
            <li>
            Thursday lost -3,000THB
            </li>
            <li>
            Friday did not play
            </li>
            <li>
            Saturday won 5,000THB
            </li>
            <li>
            Sunday won 5,000THB
            </li>
          </ul>
          </li>
          <li>SG8Bet will credit 50THB into Member A account on the next following Monday.</li>
        </ul>
        <br/>
        <h2>For Example</h2>
        <ul>
          <li>
          Member A refer Member C to register and play SG8Bet games on a Monday.
          </li>
          <li>
          Member C deposit and play SG8Bet games from Monday to Sunday.
          </li>
          <li>Member C lost a total of -1,000THB from Monday to Sunday.
          <ul>
            <li>
            Monday won 500THB
            </li>
            <li>
            Tuesday did not play
            </li>
            <li>
            Wednesday lost -1,000THB
            </li>
            <li>
            Thursday won 500THB
            </li>
            <li>
            Friday did not play
            </li>
            <li>
            Sunday lost -2,000THB
            </li>
            <li>
            Saturday won 1,000THB
            </li>
          </ul>
          </li>
          <li>Member A will not earn any Referral Bonus from Member C this week.</li>
        </ul>
        <br/>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>Referrer must be an registered SG8Bet member.</li>
          <li>
          Referrer will earn a total of 0.5% from their referees' total weekly (Monday to Sunday) won game credits only.
          </li>
          <li>Game credits will be automatically deposit into referrer's account every Monday after 12:00PM (GMT+7)</li>
        </ol>
      </div>
      </div>
      </div>
    </>
  );
}
