import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import ReactDOM from "react-dom/client";
import App from "./App";
import Promotion from "./pages/Promotion";
import LiveCasino from "./pages/LiveCasino";
import Sports from "./pages/Sports";
import Dashboard from "./pages/Dashboard";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import RequestReport from "./pages/RequestReport";
import GameReport from "./pages/GameReport";
import Verification from "./api/Verification";
import Vip from "./pages/Vip";
import Rtg from "./pages/slots/RTG";
import Spadegaming from "./pages/slots/Spadegaming";
import Bng from "./pages/slots/Bng";
import Jili from "./pages/slots/Jili";
import Playson from "./pages/slots/Playson";
import Netent from "./pages/slots/Netent";
import Redtiger from "./pages/slots/Redtiger";
import Pgsoft from "./pages/slots/Pgsoft";
import Cq9 from "./pages/slots/Cq9";
import Playstar from "./pages/slots/Playstar";
import Ygg from "./pages/slots/Ygg";
import PromotionPage from "./pages/promotion/DepositBonus";
import ReferralBonus from "./pages/promotion/ReferralBonus";
import RewardPoints from "./pages/promotion/RewardPoints";
import PageNotFound from "./pages/PageNotFound";
import AboutUs from "./pages/footer/AboutUs";
import Header from "./Header";
import Footer from "./Footer";
import Logout from "./snippets/LogoutButton";
import Faq from "./pages/footer/Faq";
import PrivacyAndPolicy from "./pages/footer/PrivacyAndPolicy";
import TermsAndCondition from "./pages/footer/TermsAndCondition";
import ResponsibleGaming from "./pages/footer/ResponsibleGaming";
import SelfExclusion from "./pages/footer/SelfExclusion";
import Slots from "./pages/slots/Slots";
import PopUpModal from "./snippets/PopUpModal";
import "./style/style.css";
import "./style/accountStyle.css";
import Blog from "./pages/Blog/Blog";
import LatestPromotions from "./pages/Blog/blog_promotion/LatestPromotions";
import DownloadMobileApp from "./pages/Blog/blog_promotion/DownloadMobileApp";
import i18n from "./i18n";
import Fishing from "./pages/Fishing";
import Translator from './snippets/Translator'
import Cmd from "./pages/sportsbook/Cmd";
import Sbobet from "./pages/sportsbook/Sbobet";
import Pinnacle from "./pages/sportsbook/Pinnacle";
import Pk10Keno from "./pages/Pk10Keno";
import Pk10 from "./pages/Pk10";
import Keno from "./pages/Keno";
import PragmaticPlay from "./pages/slots/PragmaticPlay";
import Gameplay from "./pages/slots/Gameplay";
import YL from "./pages/slots/Yl";
import Popok from "./pages/slots/Popok";
import Betsoft from "./pages/slots/Betsoft";
import Playtech from "./pages/slots/Playtech";
import AdvantPlay from "./pages/slots/AdvantPlay";
import MannaPlay from "./pages/slots/MannaPlay";
import KingMidas from "./pages/slots/KingMidas";
import DragonGaming from "./pages/slots/DragonGaming";
import TTG from "./pages/slots/TTG";
import Evolution from "./pages/slots/Evolution";

export const routes = [
  { path: "/", element: <App /> },
  { path: "/promotion/", element: <Promotion /> },
  { path: "/live-casino/", element: <LiveCasino /> },
  { path: "/sportsbook/", element: <Sports /> },
  { path: "/fishing/", element: <Fishing /> },
  { path: "/account/dashboard/", element: <Dashboard /> },
  { path: "/account/deposit/", element: <Deposit /> },
  { path: "/account/withdraw/", element: <Withdraw /> },
  { path: "/account/request-report/", element: <RequestReport /> },
  { path: "/account/game-report/", element: <GameReport /> },
  { path: "account/verification/", element: <Verification /> },
  { path: "/slots/", element: <Slots /> },
  { path: "/slots/spadegaming/", element: <Spadegaming /> },
  { path: "/slots/rtg/", element: <Rtg /> },
  { path: "/slots/bng/", element: <Bng /> },
  { path: "/slots/jili/", element: <Jili /> },
  { path: "/slots/playson/", element: <Playson /> },
  { path: "/slots/netent/", element: <Netent /> },
  { path: "/slots/redtiger/", element: <Redtiger /> },
  { path: "/slots/pgsoft/", element: <Pgsoft /> },
  { path: "/slots/cq9/", element: <Cq9 /> },
  { path: "/slots/playstar/", element: <Playstar /> },
  { path: "/slots/ygg/", element: <Ygg /> },
  { path: "/vip/", element: <Vip /> },
  { path: "/promotion/deposit-bonus/", element: <PromotionPage /> },
  { path: "/promotion/referral-bonus/", element: <ReferralBonus /> },
  { path: "/promotion/reward-points/", element: <RewardPoints /> },
  { path: "*", element: <PageNotFound /> },
  { path: "/about-us/", element: <AboutUs /> },
  { path: "/faq/", element: <Faq /> },
  { path: "/privacy-policy/", element: <PrivacyAndPolicy /> },
  { path: "/terms-and-condition/", element: <TermsAndCondition /> },
  { path: "/responsible-gaming/", element: <ResponsibleGaming /> },
  { path: "/self-exclusion/", element: <SelfExclusion /> },
  { path: "/blog/", element: <Blog /> },
  { path: "/latest-promotion/", element: <LatestPromotions /> },
  { path: "/news/", element: <Blog /> },
  { path: "/news/latest-promotion/", element: <LatestPromotions /> },
  { path: "/news/download-sg8-casino-mobile-app/", element: <DownloadMobileApp /> },
  { path: "/sportsbook/cmd368/", element: <Cmd /> },
  { path: "/sportsbook/sbobet/", element: <Sbobet /> },
  { path: "/sportsbook/pinnacle/", element: <Pinnacle /> },
  { path: "/pk10-keno/", element: <Pk10Keno /> },
  { path: "/pk10-keno/pk10/", element: <Pk10 /> },
  { path: "/pk10-keno/keno/", element: <Keno /> },
  { path: "/slots/pragmatic-play/", element: <PragmaticPlay /> },
  { path: "/slots/gameplay/", element: <Gameplay /> },
  { path: "/slots/yl/", element: <YL /> },
  { path: "/slots/popok/", element: <Popok /> },
  { path: "/slots/betsoft/", element: <Betsoft /> },
  { path: "/slots/playtech/", element: <Playtech /> },
  { path: "/slots/advantplay/", element: <AdvantPlay /> },
  { path: "/slots/mannaplay/", element: <MannaPlay /> },
  { path: "/slots/kingmidas/", element: <KingMidas /> },
  { path: "/slots/dragongaming/", element: <DragonGaming /> },
  { path: "/slots/ttg/", element: <TTG /> },
  { path: "/slots/evolution/", element: <Evolution /> },

];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <PopUpModal />
    <Header />
    <div id="content"  className="site-content sportsbook-content">
      <div id="primary" className="content-area" style={{backgroundImage: 'url("/images/main-bg.webp")', width: '100%', backgroundSize: '100%'}}>
        <main id="main" className="site-main">
          <Logout />
          <Router>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </Router>
        </main>
      </div>
    </div>
    <Footer />
  </>
);