import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PaymentMethod from './../snippets/PaymentMethod';
import { currentPath, currentDomain } from '../App';

export const promotionList = [
  {
    id: 1,
    title: "Referral Rewards",
    desc: "Refer friends to join SG8Bet for extra bonuses!",
    alt: "Referral Bonus",
    imgSmall: "/images/referralbonus.jpg",
    imgLarge: "/images/referral-banner.webp",
    promoClick: "/promotion/referral-bonus/",
    priority: 3,
  },
  {
    id: 2,
    title: "500THB More on First Deposit",
    desc: "Get 500THB more upon your first deposit!",
    alt: "Deposit Bonus",
    imgSmall: "/images/deposit-banner-small.jpg",
    imgLarge: "/images/deposit-banner.webp",
    promoClick: "/promotion/deposit-bonus/",
    priority: 2,
  },
  {
    id: 3,
    title: "SG8Bet Reward Points",
    desc: "Exchange Reward Points into game credits!",
    alt: "Reward Points",
    imgSmall: "/images/reward-banner-small.jpg",
    imgLarge: "/images/reward-banner.webp",
    promoClick: "/promotion/reward-points/",
    priority: 1,
  },

];

function Promotion() {
  const [visibleId, setVisibleId] = useState(null);

  // Sort promotions by priority, highest first
  const sortedPromotionList = [...promotionList].sort((a, b) => a.priority - b.priority);

  const listItems = sortedPromotionList.map((data) => (
    <li className={`promoBox ${data.classname || ""}`} key={data.id}>
      <img
        className="promotionBannerMobile"
        src={data.imgLarge}
        alt={data.alt}
      />
      <img className="promotionBanner" src={data.imgSmall} alt={data.alt} />
      <div className="promotionContent">
        <p className="promotion-title">{data.title}</p>
        <p className="promotion-info">{data.desc}</p>
        <div className="promolink-container">
          <a className="yellow-btn" href={data.promoClick}>
            T&C
          </a>
        </div>
      </div>
    </li>
  ));

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          SG8 Casino Promotions | 150% Deposit Bonus & Up to 6% Cashback
        </title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Unlock amazing promotions at SG8 Casino with a 150% deposit bonus and up to 6% cashback. Maximize your gaming potential and start winning today!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1>SG8 Casino Promotions</h1>
              <p>Maximize your gaming potential and start winning today!</p>
            </div>
          </div>
          <div className="promotionContentarea">
            <ul className="promotionWrapper">{listItems}</ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Promotion;
