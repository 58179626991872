import React, { useState, useEffect } from 'react';
import { apiJSONURL } from './LiveCasino';
import axios from 'axios';
import qs from 'qs';

const promptpayBtn = <div id="promptpayBtn"><img src="/images/promptpay-icon.webp" alt="promptpay"/>PromptPay</div>;
const truewalletBtn = <div id="truewalletBtn"><img src="/images/truewallet-icon.webp" alt="truewallet"/>True Wallet</div>;
const banktransferBtn = <div id="truewalletBtn"><img src="/images/banktransfer-icon.webp" alt="bank"/>Bank Transfer</div>;

const Deposit = () => {

  const setting = true;

  const defaultVisibleId = 'promptpay';

  const getDefaultMethod = (id) => {
    if (id === 'truewallet') {
      return 'truewallet';
    } else if (id === 'promptpay') {
      return 'promptpay';
    } else if (id === 'banktransfer') {
      return 'banktransfer';
    }
    return null;
  };

  const getReturnUrl = () => {
    return `${window.location.protocol}//${window.location.hostname}/account/request-report/`;
  };

  const [visibleId, setVisibleId] = useState(defaultVisibleId);
  const [amt, setAmount] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [bonusId, setBonusId] = useState('-1');
  const [method, setMethod] = useState(getDefaultMethod(defaultVisibleId));
  const [bonusList, setBonusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minValue, setMinValue] = useState('');

  useEffect(() => {
    const fetchBonusList = async () => {
      const username = localStorage.getItem('username');

      if (username) {
        try {
          const response = await axios.post(apiJSONURL,
            qs.stringify({
              cmd: 'getBonusList',
              u: username
            }), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          );

          const data = response.data;
          if (setting) {
            if (data.ifDeposited === false) {
              setMinValue('101');
            } else {
              setMinValue('101');
            }
          }

          const bonusData = response.data.bonus;
          if (bonusData) {
            setBonusList(bonusData);
            setBonusId('1'); // Set default value to "No Bonus" after data is populated
          } else {
            console.error('Bonus data not found in the response:', response.data);
          }
        } catch (error) {
          console.error('Error fetching bonus list:', error);
        }
      } else {
        console.error('Username not found in local storage');
      }
    };

    fetchBonusList();
  }, []);
  

  const checkDepositBonus = (bonusId, amount) => {
    let isError = false;
    let message = '';
    if (bonusId == 10) {
      if (amount != 500) { 
        message = "Invalid amount, only valid for 500 deposit.";
        isError = true;
      }
    } else if (bonusId == 11) {
      if (amount != 1000) { 
        message = "Invalid amount, only valid for 1000 deposit.";
        isError = true;
      }
    } else if (bonusId == 12) {
      if (amount != 2000) { 
        message = "Invalid amount, only valid for 2000 deposit.";
        isError = true;
      }
    } else if (bonusId == 13) {
      if (amount < 3000) { 
        message = "Invalid amount, only valid for 3000 and above deposit.";
        isError = true;
      }
    }
    setAlertMessage(message);
    return isError;
  };

  const handleDepositClick = (id) => {
    setVisibleId(id);
    setMethod(getDefaultMethod(id));
    setAmount('');
    setBonusId('1');
    setAlertMessage('');
  };

  const handleSubmit = async (additionalPayload = {}) => {
    const username = localStorage.getItem('username');
    const amount = parseFloat(amt);
    const selectedBonusId = parseInt(bonusId);
  
    if (amount < parseFloat(minValue)) {
      setAlertMessage(`Amount must be at least ${minValue}`);
      return;
    }
  
    if (checkDepositBonus(selectedBonusId, amount)) {
      return;
    }
  
    setLoading(true);
    setAlertMessage('');
  
    try {
      const response = await axios.post(apiJSONURL, 
        qs.stringify({
          cmd: 'deposit-request',
          method,
          u: username,
          returnUrl: getReturnUrl(),
          amt: amount,
          bonusid: selectedBonusId,
          ...additionalPayload
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
  
      console.log('API response:', response);
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        setAlertMessage(response.data.emsg);
      }
    } catch (error) {
      console.error('API call error:', error);
      setAlertMessage('An unexpected error occurred. Please contact support for assistance.');
    } finally {
      setLoading(false);
    }
  };

  const handletruewalletSubmit = async () => {
    await handleSubmit();
  };

  const handlepromptpaySubmit = async () => {
    await handleSubmit();
  };

  const handlebanktransferSubmit = async () => {
    await handleSubmit();
  };

  return (

      <>
        <div className="account-container">
          <div className="page-headlines-container">
            <div className="page-h2-text-style">
              <h2>Deposit Request</h2><span>Get our Best Deals from SG8.Casino</span>
            </div>
          </div>
          <div className="deposit-container">
          <div className="depositnav-nav nav-tabs">
            <a className={`${visibleId === 'promptpay' ? 'active' : ''}`} onClick={() => handleDepositClick('promptpay')}>{promptpayBtn}</a>
            <a className={`${visibleId === 'truewallet' ? 'active' : ''}`} onClick={() => handleDepositClick('truewallet')}>{truewalletBtn}</a>
            <a className={`${visibleId === 'banktransfer' ? 'active' : ''}`} onClick={() => handleDepositClick('banktransfer')}>{banktransferBtn}</a>
          </div>
          <div className="all-form-container">

          {visibleId === 'promptpay' && <form id="promptpay">
            <div className="form-title">{promptpayBtn}</div>
            <div style={{ textAlign: "center" }}>
              <div className="notice">
                <div><strong>Notice: </strong>Please contact us if game credits are not credited to your account within 10 minutes upon successful payment.</div>
              </div>
              <div className="row">
                <div className="hLabel">Deposit Amount</div>
                <div className="input-container">
                  <input id="promptpay-amount" className="form-control txt-amount" autoComplete="off" max="30000" maxLength="10" min={minValue} name="amt" required="required" type="text" placeholder={`Min:${minValue}, Max:50,000`} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                  <div className="hLabel">Bonus</div>
                  <div className="input-container">
                  <select name="bonusid" id="bonusListNonBTC" className="form-control bonusList" value={bonusId} onChange={(e) => setBonusId(e.target.value)}>
                    <option value="-1">-- Select Bonus --</option>
                    {bonusList.length > 0 && <option value="1">No Bonus</option>}
                      {bonusList && bonusList
                        .filter((bonus) => bonus.bonusid !== '1')
                        .map((bonus) => (
                      <option value={bonus.bonusid} key={bonus.bonusid}>
                        {bonus.bonusnameEN}
                      </option>
                      ))}
                  </select>
                  </div>
              </div>
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button className="yellow-btn" type="button" onClick={handlepromptpaySubmit}>SUBMIT</button>
                </div>
              </div>
            </div>
            </form>}

            {visibleId === 'truewallet' && <form id="truewallet">
            <div className="form-title">{truewalletBtn}</div>
            <div style={{ textAlign: "center" }}>
              <div className="notice">
                <div><strong>Notice: </strong>Please contact us if game credits are not credited to your account within 10 minutes upon successful payment.</div>
              </div>
              <div className="row">
                <div className="hLabel">Deposit Amount</div>
                <div className="input-container">
                  <input id="truewallet-amount" className="form-control txt-amount" autoComplete="off" max="30000" maxLength="10" min={minValue} name="amt" required="required" type="text" placeholder={`Min:${minValue}, Max:50,000`} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                  <div className="hLabel">Bonus</div>
                  <div className="input-container">
                  <select name="bonusid" id="bonusListNonBTC" className="form-control bonusList" value={bonusId} onChange={(e) => setBonusId(e.target.value)}>
                    <option value="-1">-- Select Bonus --</option>
                    {bonusList.length > 0 && <option value="1">No Bonus</option>}
                      {bonusList && bonusList
                        .filter((bonus) => bonus.bonusid !== '1')
                        .map((bonus) => (
                      <option value={bonus.bonusid} key={bonus.bonusid}>
                        {bonus.bonusnameEN}
                      </option>
                      ))}
                  </select>
                  </div>
              </div>
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button className="yellow-btn" type="button" onClick={handletruewalletSubmit}>SUBMIT</button>
                </div>
              </div>
            </div>
            </form>}

            {visibleId === 'banktransfer' && <form id="banktransfer">
            <div className="form-title">{banktransferBtn}</div>
            <div style={{ textAlign: "center" }}>
              <div className="notice">
                <div><strong>Notice: </strong>Please contact us if game credits are not credited to your account within 10 minutes upon successful payment.</div>
              </div>
              <div className="row">
                <div className="hLabel">Deposit Amount</div>
                <div className="input-container">
                  <input id="bank-amount" className="form-control txt-amount" autoComplete="off" max="30000" maxLength="10" min={minValue} name="amt" required="required" type="text" placeholder={`Min:${minValue}, Max:50,000`} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                  <div className="hLabel">Bonus</div>
                  <div className="input-container">
                  <select name="bonusid" id="bonusListNonBTC" className="form-control bonusList" value={bonusId} onChange={(e) => setBonusId(e.target.value)}>
                    <option value="-1">-- Select Bonus --</option>
                    {bonusList.length > 0 && <option value="1">No Bonus</option>}
                      {bonusList && bonusList
                        .filter((bonus) => bonus.bonusid !== '1')
                        .map((bonus) => (
                      <option value={bonus.bonusid} key={bonus.bonusid}>
                        {bonus.bonusnameEN}
                      </option>
                      ))}
                  </select>
                  </div>
              </div>
              <div className="row">
                <div style={{ textAlign: "center" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button className="yellow-btn" type="button" onClick={handlebanktransferSubmit}>SUBMIT</button>
                </div>
              </div>
            </div>
            </form>}

          </div>
          {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
          </div>
        </div>
      </>
  );
};
export default Deposit;