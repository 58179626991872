import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import PaymentMethod from './../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../App';
import { useTranslation } from 'react-i18next';
import Translator from "../snippets/Translator"
export const tokenURL = process.env.REACT_APP_TOKEN_URL;
export const wlpLaunchURL = process.env.REACT_APP_WLP_LAUNCH_URL;
export const httpsLaunchURL = process.env.REACT_APP_HTTPS_LAUNCH_URL;
export const apiJSONURL = process.env.REACT_APP_API_JSON_URL;
export const totalFrames = 77;
const jsonString =  '{"1": "Please register and login to play the game."}'

export const jsonObject = JSON.parse(jsonString);
export const launchGame = async (
  url,
  gameId,
  isMobile,
  lang,
  transfer,
  isHttps,
  setLaunching,
  setCurrentFrame,
  setAnimationComplete,
  showAlert
) => {
  let mobile = isMobile ? 1 : 0;
  let username = localStorage.getItem("username");
  let launchURL = url;

  setLaunching(true);
  setCurrentFrame(19);
  setAnimationComplete(false);

  if (document.body.classList.contains("isLoggedIn")) {
    try {
      const tokenResponse = await axios.post(
        apiJSONURL,
        `cmd=getToken&u=${username}`
      );
      const tokenResult = tokenResponse.data;
      username = tokenResult.token;

      const gameTokenResponse = await axios.post(
        tokenURL,
        `u=${username}&g=${gameId}&t=${transfer}&m=${mobile}`
      );
      const gameTokenResult = gameTokenResponse.data;
      const sessionToken = gameTokenResult.token;

      if (gameTokenResult.e) {
        showAlert(gameTokenResult.emsg);
        setLaunching(false);
        return;
      }

      launchURL =
        isHttps && !gameId.startsWith("33")
          ? `${httpsLaunchURL}?s=${sessionToken}&l=${lang}`
          : `${wlpLaunchURL}?s=${sessionToken}&l=${lang}`;

      if (isMobile) {
        launchURL += `&m=1&t=1&h=${encodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.hostname +
            window.location.pathname
        )}`;
      }
      if (gameId.startsWith("47")) {
        launchURL += "&I=0";
      }
      if (gameId.startsWith("58") && !isMobile) {
        launchURL += `&h=${encodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.hostname +
            window.location.pathname
        )}`;
      }

      if (!gameId.startsWith("25") && !gameId.startsWith("62")) {
        handleRecentGamesCookie(gameId);
      }

      if (document.querySelector(".gameIframe")?.style.display === "block") {
        document.querySelector(".gameIframe").src = "";
      }

      if (!launchURL) {
        showAlert(jsonObject);
      } else {
        handleGameLaunch(launchURL, isMobile, gameId, lang, showAlert);
      }
      setLaunching(false);
    } catch (error) {
      console.error(error);
      setLaunching(false);
    }
  } else {
    if (!username && !launchURL) {
      showAlert("Please register and login to play the game.");
    } else {
      handleGameLaunch(launchURL, isMobile, gameId, lang, showAlert);
    }
  }
};

const handleGameLaunch = (launchURL, isMobile, gameId, lang, showAlert) => {
  if (!isMobile) {
    const win = window.open(launchURL, "popup", "width=1024,height=768");
    if (!win) {
      showAlert("Windows Blocked! Please allow popup to play the game.");
    }
  } else {
    if (gameId.startsWith("62")) {
      const win = window.open(launchURL, "popup", "width=1024,height=768");
      if (!win) {
        showAlert("Windows Blocked! Please allow popup to play the game.");
      }
    } else {
      window.top.location.href = launchURL;
    }
  }
};

const handleRecentGamesCookie = (gameId) => {
  const cookieName = "recentGames";
  let cookies = getCookie(cookieName);
  if (!cookies) {
    setCookie(cookieName, gameId, 30);
  } else {
    if (cookies.includes(gameId)) {
      cookies = cookies.replace(`,${gameId}`, "").replace(`${gameId},`, "");
      cookies = `${gameId},${cookies}`;
    } else {
      cookies = `${gameId},${cookies}`;
      const cookiesArray = cookies.split(",");
      if (cookiesArray.length > 5) {
        cookies = cookiesArray.slice(0, 5).join(",");
      }
    }
    setCookie(cookieName, cookies, 30);
  }
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const LiveCasino = () => {
  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { t, i18n } = useTranslation();

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  useEffect(() => {
    let interval;
    if (launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 25);
    }
    return () => clearInterval(interval);
  }, [launching]);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Best Online Live Casino in the Philippines 2024 | SG8 Casino</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the best online live casino in the Philippines in 2024 with SG8 Casino. Play Evolution Games – SA Gaming – AFB Gaming"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="page-headlines-container">
        <div className="pageHeaderText">
          <h1>Ultimate Live Casino</h1>
        </div>
      </div>

      <div className="liveCasino">
        <Game
          imgSrc="/images/livedealer/evolution.jpg"
          imgSrc2="/images/livedealer/evolution.png"
          imgBg="/images/livedealer/bg-1.jpg"
          altText="live casino powered by evolution"
          gameId="25000"
          gameName="Evolution"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/ae-dealer.jpg"
          imgSrc2="/images/livedealer/ae-logo.png"
          altText="live casino powered by SA Gaming"
          imgBg="/images/livedealer/bg-2.jpg"
          gameId="36001"
          gameName="SA Gaming"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/all_bet.jpg"
          imgSrc2="/images/livedealer/allbet_logo.png"
          imgBg="/images/livedealer/bg-3.jpg"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="AFB"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/n2-live.jpg"
          imgSrc2="/images/livedealer/n2live.png"
          imgBg="/images/livedealer/bg-3.jpg"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="AFB"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/world-dealer.jpg"
          imgSrc2="/images/livedealer/world-entertainment.png"
          imgBg="/images/livedealer/bg-1.jpg"
          altText="live casino powered by evolution"
          gameId="25000"
          gameName="Evolution"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/game_play.jpg"
          imgSrc2="/images/livedealer/gp_logo.png"
          altText="live casino powered by SA Gaming"
          imgBg="/images/livedealer/bg-2.jpg"
          gameId="36001"
          gameName="SA Gaming"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/sbobet-live.jpg"
          imgSrc2="/images/livedealer/sbobet-logo.png"
          imgBg="/images/livedealer/bg-3.jpg"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="AFB"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />

        <Game
          imgSrc="/images/livedealer/sa_gaming.jpg"
          imgSrc2="/images/livedealer/sa_gaming.png"
          imgBg="/images/livedealer/bg-3.jpg"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="AFB"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/og-dealer.jpg"
          imgSrc2="/images/livedealer/og-logo.png"
          imgBg="/images/livedealer/bg-1.jpg"
          altText="live casino powered by evolution"
          gameId="25000"
          gameName="Evolution"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/pragmatic.jpg"
          imgSrc2="/images/livedealer/pragmatic-logo.png"
          altText="live casino powered by SA Gaming"
          imgBg="/images/livedealer/bg-2.jpg"
          gameId="36001"
          gameName="SA Gaming"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        <Game
          imgSrc="/images/livedealer/playtech.jpg"
          imgSrc2="/images/livedealer/playtech-logo.png"
          imgBg="/images/livedealer/bg-3.jpg"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="AFB"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />

        <Game
          imgSrc="/images/livedealer/popok.jpg"
          imgSrc2="/images/livedealer/popok-logo.png"
          imgBg="/images/livedealer/bg-3.jpg"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="AFB"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
        />
        
      </div>

      <AlertModal open={alertOpen} close={() => setAlertOpen(false)} message={alertMessage} />
    </>
  );
};

const Game = ({
  imgSrc,
  imgSrc2,
  imgBg,
  altText,
  gameId,
  gameName,
  launchGame,
  launching,
  currentFrame,
  animationComplete,
  setLaunching,
  setCurrentFrame,
  setAnimationComplete,
  showAlert,
}) => {
  const imageRef = useRef(null);
  const isLoggedIn = document.body.classList.contains("isLoggedIn");
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="liveCasinoContent">
        {launching && isLoggedIn && (
          <div className="overlay">
            <div className="loadingBar">
              <div className={`spinner ${animationComplete ? "pop" : ""}`}>
                <img
                  ref={imageRef}
                  src={`/images/frameLoading/sg8logo_${String(
                    currentFrame
                  ).padStart(5, "0")}.png`}
                  alt="Loading..."
                />
              </div>
            </div>
          </div>
        )}
        <div className="liveContainer">
          <img className="liveCasinoLogo" src={imgSrc2} alt={altText} />
          <img className="charMain" src={imgSrc} alt={altText} />
          <div className="liveCasinoBtn">
            <div
              className="hidden-xs yellow-btn"
              onClick={() =>
                launchGame(
                  "",
                  gameId,
                  true,
                  "en",
                  0,
                  true,
                  setLaunching,
                  setCurrentFrame,
                  setAnimationComplete,
                  showAlert
                )
              }
            >
              {t('button.1')}
            </div>
            <div
              className="visible-xs yellow-btn lcBtn"
              onClick={() =>
                launchGame(
                  "",
                  gameId,
                  true,
                  "en",
                  0,
                  true,
                  setLaunching,
                  setCurrentFrame,
                  setAnimationComplete,
                  showAlert
                )
              }
            >
              Play Now
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AlertModal = ({ open, close, message }) => (
  <Popup open={open} closeOnDocumentClick onClose={close} modal nested>
    <div className="popupError" style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}>
      <img src="/images/live-dealer-mobile-icon.webp" alt="" />
      <div className="content">{message}</div>
      <div className="actions">
        <button className="yellow-btn" onClick={close}>OK</button>
      </div>
    </div>
  </Popup>
);

export default LiveCasino;
