import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import SlickHomebanner from "./snippets/MainSlider";
import PaymentMethod from "./snippets/PaymentMethod";
import FeaturedGamesList from "./snippets/FeaturedGameList";
import ProviderListHome from "./snippets/ProviderListHome";
import { launchGame, AlertModal } from "./pages/LiveCasino";
import { getCookie, setCookie } from './cookies';
import { Helmet } from "react-helmet";
import trackPage from './analytics';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
export const currentPath = window.location.pathname;
export const currentDomain = window.location.origin;
const gtag = process.env.REACT_APP_GTAG;

const tagManagerArgs = {
  gtmId: gtag
};

TagManager.initialize(tagManagerArgs);

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    trackPage(location.pathname + location.search);
  }, [location]);
};

function App() {
  const [loading, setLoading] = useState(true);
  const sliderCurrentPage = "all";
  const bannerCurrentPage = "all";

  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
const { t, i18n } = useTranslation();
  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

   useEffect(() => {
    const language = getCookie('i18next') || 'th';
    i18n.changeLanguage(language);
    setCookie('i18next', language, 365); 
  }, [i18n]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);

  usePageTracking(); 

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8 Casino Filipino Ultimate Gaming Experience | PAGCOR Licensed 2024</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Ultimate gaming experience with SG8 Casino Filipino, a PAGCOR licensed casino for 2024. Enjoy the best online slots, live casino, and sports betting. Play now!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <AlertModal open={alertOpen} close={() => setAlertOpen(false)} message={alertMessage} />
      <div>
        <div className="main-wrapper">
          <div className="main-content-area">
            <SlickHomebanner
              sliderCurrentPage={sliderCurrentPage}
              bannerCurrentPage={bannerCurrentPage}
            />
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>Hottest Slots</span> <h2>Spin Now!</h2>
                </div>
                <div className="ProviderListHome"></div>
                <div className="c2a-wrapper">
                  <a href="/slots/" className="yellow-btn">
                    {t('button.3')}
                  </a>
                </div>
              </div>
              <ProviderListHome />
              <div className="hottest-gamelist">
                <FeaturedGamesList />
              </div>
            </div>
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>Hot Live Casino</span> <h2>Bet Now!</h2>
                </div>
                <div className="c2a-wrapper">
                  <a href="/live-casino/" className="yellow-btn">
                    {t('button.3')}
                  </a>
                </div>
              </div>
              <div className="hotLDGames">
                <div className="gameLD">
                  <img src="/images/evolution-baccarat.webp" alt="evolution games baccarat" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/evolution-crazy-time.webp" alt="Evolution Games Crazy Time" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img
                    src="/images/evolution-baccarat-first-person.webp"
                    alt="Evolution Game First Person Baccarat"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/sa-gaming-baccarat.webp" alt="SA Gaming on SG8 Casino" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "36001",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/afb-casino-baccarat.webp" alt="AFB Casino Live on SG8 Casino" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "74001",
                        true,
                        "en",
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
              </div>
            </div>
            <div className="featured-slots-container">
              <div className="service-selector">
                <div className="services-selector-box">
                  <img src="/images/slot-selector.webp" alt="Best Online Slot in the Philippines at SG8 Casino | JILI Games" />
                  <span>{t('footer-nav.7')}</span>
                  <a href="/slots/jili/" className="yellow-btn">
                    {t('button.1')}
                  </a>
                </div>
                <div className="services-selector-box">
                  <img src="/images/ld-selector.webp" alt="Real Person Live Casino Live at SG8 Casino" />
                  <span>{t('footer-nav.8')}</span>
                  <a href="/live-casino/" className="yellow-btn">
                    {t('button.1')}
                  </a>
                </div>
                <div className="services-selector-box">
                  <img src="/images/sprotsbook-selector.webp" alt="Live Betting Sportsbook at SG8 Casino" />
                  <span>{t('footer-nav.9')}</span>
                  <a href="/sportsbook/" className="yellow-btn">
                    {t('button.2')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default App;
