import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';

const bngFilterOptions = [
  { value: 'all', label: 'All' },
  { value: 'featured', label: 'Featured' },
  { value: 'slots', label: 'Slots' },
];

function BNG() {
  const [currentFilter, setCurrentFilter] = useState('all');
  const [sliderCurrentPage, setSliderCurrentPage] = useState('BNG');
  const [bannerCurrentPage, setBannerCurrentPage] = useState('BNG');
  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BNG Games X SG8 Casino | Top Online Slot Games 2024</title> 
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta name="description" content="Explore the top online slot games of 2024 with BNG Games at SG8 Casino. Enjoy premium slots and exciting gameplay. Play today"/>
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
          <SlotProviderList initialProvider="bng" />
          <div className="slot-filter-search-wrapper">
            <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <div className="filter-slots">
              <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={bngFilterOptions} />
            </div>
          </div>
        </div>
      </div>
      <GameList provider="booongo" filter={currentFilter} searchQuery={searchQuery} />
    </>
  );
}

export default BNG;
