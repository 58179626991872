import React from "react";

export default function Back() {
  const handleClick = (e) => {
    e.preventDefault();
    window.history.go(-1);
  };
  return <>
  <div className="sportsBtn">
    <a onClick={handleClick} className="yellow-btn">Back</a>
  </div>
  </>;
}
