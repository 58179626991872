import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';

const jiliFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'featured', label: 'Featured' },
    { value: 'slots', label: 'Slots' },
    { value: 'table', label: 'Table' },
    { value: 'arcade', label: 'Arcade' },
];

function Jili() {
    const [currentFilter, setCurrentFilter] = useState('all');
    const [sliderCurrentPage, setSliderCurrentPage] = useState('JILI');
    const [bannerCurrentPage, setBannerCurrentPage] = useState('JILI');
    const [searchQuery, setSearchQuery] = useState("");

    const handleFilterChange = (filter) => {
        setCurrentFilter(filter);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>JILI Games X SG8 Casino | Best Online Slot Games 2024</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Discover the best online slot games of 2024 with JILI Games at SG8 Casino. Enjoy top-quality slots and thrilling gameplay. Play now!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
                    <SlotProviderList initialProvider="jili" />
                    <div className="slot-filter-search-wrapper">
                        <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                        <div className="filter-slots">
                            <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={jiliFilterOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <GameList provider="jili" filter={currentFilter} searchQuery={searchQuery} />
        </>
    );
}

export default Jili;
