import React from "react";
import { Helmet } from "react-helmet";
import SBLaunch from "../../snippets/SBLaunch";
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';
import SportsbookFooter from "../../snippets/SportsbookFooter";
import Back from "./Back";
function Sbobet() {
  const gameId = "62001";

  const openGameInNewWindow = () => {
    const launchURL = document.querySelector(".gameIframe")?.src;
    if (launchURL) {
      window.open(
        launchURL,
        "GameWindow",
        "width=800,height=600,resizable=yes,scrollbars=yes,status=yes"
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Premier Sportsbook Powered by Pinnacle | SG8 Casino</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Bet on your favorite sports with SG8 Casino's premier sportsbook, powered by Pinnacle. Enjoy competitive odds and a seamless betting experience."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1 id="pinnaclesports">Sbobet</h1>
              <Back/>
              <div className="desktop-wrapper">
                <SBLaunch
                  onOpenNewWindow={openGameInNewWindow}
                  showIframe={false}
                />
              </div>
              <div className="sportsbookMobileContainer">
                <a
                  href="#"
                  onClick={openGameInNewWindow}
                  className="sportsbookMobile mobile-wrapper"
                  style={{
                    backgroundImage: 'url("/images/sportsbook-mobile.webp")',
                  }}
                >
                  <button>Play Now</button>
                </a>
              </div>
            </div>
          </div>
          <div className="sportsbook-game-selector desktop-wrapper">
            <SBLaunch gameId={gameId} />
          </div>
        </div>
      </div>
      <SportsbookFooter/>
    </>
  );
}

export default Sbobet;
