import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function PromotionPage() {
  const title = "Reward Points";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("RewardPoints");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("RewardPoints");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8Bet: Reward Points</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8Bet with our reward points. Exchange Reward Points into game credits or other bonuses and prizes!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />

<div className="main-wrapper-promo">
<div className="main-content-area" >
        <h2>
        SG8Bet Reward Points
        </h2>
<p>Reward Points are points earned by members as they play any SG8Bet games. Members will be able to exchange Reward Points into game credits or other bonuses and prizes that SG8Bet offer.</p>
       <p>SG8Bet is offering all members 0.05% exchange rate from Reward Points into Game Credits.</p><br/>
        <h2>An Example of SG8Bet exchange rate table</h2>

            <div className="table-main">
<table>
<thead>
    <th>Turnover</th>
    <th>Reward Points</th>
    <th>Game Credits</th>
</thead>
<tbody>
              <tr>
                <td>1,000</td>
                <td>10,000</td>
                <td>5.00</td>
              </tr>
              <tr>
                <td>10,000</td>
                <td>100,000</td>
                <td>50.00</td>
              </tr>
              <tr>
                <td>100,000</td>
                <td>1,000,000</td>
                <td>500.00</td>
              </tr>
              </tbody>
</table>
</div>
<p>SG8Bet Reward Points system is much better bonus compared to others.</p>
<div className="table-main">
<table>
    <thead>
        <th colspan="3" className="red-header">Website A</th>
    </thead>
<thead>
    <th>Promotion</th>
    <th>Turnover</th>
    <th>Bonus Credits</th>
</thead>
<tbody>
              <tr>
                <td>Rebate 0.50% on Sports</td>
                <td>Sports: 5,000THB</td>
                <td>Sports: 25THB</td>
              </tr>
              <tr>
                <td>No Promotion for other games</td>
                <td>Casino: 5,000THB</td>
                <td>Casino: 0THB</td>
              </tr>
              <tr>
                <td>With many conditions and limitations</td>
                <td>Total: 10,000THB</td>
                <td>Total: 25THB</td>
              </tr>
              <tr>
                <td><strong>SG8Bet Reward Points</strong></td>
                <td></td>
                <td><strong>No Limits & Conditions<br/>Total: 50THB</strong></td>
              </tr>
              </tbody>
</table>
</div>
<div className="table-main">
<table>
<thead>
        <th colspan="3" className="red-header">Website B</th>
    </thead>
<thead>
    <th>Promotion</th>
    <th>Turnover</th>
    <th>Bonus Credits</th>
</thead>
<tbody>
              <tr>
                <td>Rebate 0.20% on Sports</td>
                <td>Sports: 5,000THB</td>
                <td>Sports: 10THB</td>
              </tr>
              <tr>
                <td>Rebate 0.30% on Casinos</td>
                <td>Casino: 5,000THB</td>
                <td>Casino: 15THB</td>
              </tr>
              <tr>
                <td>Rebate weekly & conditions</td>
                <td>Total: 10,000THB</td>
                <td>Total: 25THB</td>
              </tr>
              <tr>
                <td><strong>SG8Bet Reward Points</strong></td>
                <td></td>
                <td><strong>No Limits & Conditions<br/>Total: 50THB</strong></td>
              </tr>
              </tbody>
</table>
</div>

<div className="table-main">
<table>
<thead>
        <th colspan="3" className="red-header" >Website C</th>
    </thead>
<thead>
    <th>Promotion</th>
    <th>Turnover</th>
    <th>Bonus Credits</th>
</thead>
<tbody>
              <tr>
                <td>Rebate 0.20% on Sports</td>
                <td>Sports: 5,000THB</td>
                <td>Sports: 10THB</td>
              </tr>
              <tr>
                <td>Rebate 0.30% on Casinos</td>
                <td>Casino: 5,000THB</td>
                <td>Casino: 15THB</td>
              </tr>
              <tr>
                <td>Rebate 0.40% on Others</td>
                <td>Others: 5,000THB</td>
                <td><strong>Others: 20THB</strong></td>
              </tr>
              <tr>
                <td><strong>SG8Bet Reward Points</strong></td>
                <td></td>
                <td><strong>No Limits & Conditions<br/>Total: 75THB</strong></td>
              </tr>
              </tbody>
</table>
</div>
<p><strong>SG8Bet Reward Points will never expire, play more to earn more points!</strong></p><br/>
<h2>Terms & Conditions</h2>
<ul>
    <li>Conversion rate from game turnover to reward points is fixed at 0.05%</li>
    <li>Reward points will be automatically redeemed everyday after 11:00AM (GMT+7)</li>
    <li>Maximum Reward points can be redeemed each time is 3,000,000</li>
</ul>
      </div>
      </div>
      </div>
    </>
  );
}
