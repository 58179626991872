import React, { useState, useEffect } from "react";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [closeOtpForm, setCloseOtpForm] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn") === "true";
    const savedUsername = localStorage.getItem("username");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (savedRememberMe && loggedInStatus && savedUsername) {
      setIsLoggedIn(true);
      setUsername(savedUsername);
      setRememberMe(true);

      /* Calumpit Filter Code */
      // if (
      //   (window.location.pathname === "/sportsbook/" ||
      //    window.location.pathname === "/account/deposit/" ||
      //    window.location.pathname === "/account/withdraw/") &&
      //   savedUsername.startsWith("SG8JPCP")
      // ) {
      //   window.location.href = "/";
      // }
      
    }
    /* Calumpit Filter Code */
    // if (savedUsername) {
    //   const elementsToToggle = [
    //     "calumpit-filter",
    //     "depo-btn",
    //     "withdraw-btn",
    //   ];
    //   elementsToToggle.forEach((className) => {
    //     const elements = document.getElementsByClassName(className);
    //     for (let element of elements) {
    //       element.style.display = savedUsername.startsWith("SG8JPCP")
    //         ? "none"
    //         : "block";
    //     }
    //   });

    //   const promoElements =
    //     document.getElementsByClassName("sg8-calumpit-promo");
    //   for (let element of promoElements) {
    //     element.style.display = savedUsername.startsWith("SG8JPCP")
    //       ? "grid"
    //       : "none";
    //   }

    //   if (savedUsername.startsWith("SG9JPCP")) {
    //     const hiddenElements = document.getElementsByClassName("hidden");
    //     for (let element of hiddenElements) {
    //       element.style.display = "none";
    //     }
    //   }
    // } else {
    //   const promoElements =
    //     document.getElementsByClassName("sg8-calumpit-promo");
    //   for (let element of promoElements) {
    //     element.style.display = "none";
    //   }
    // }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://sg8.casino/json/wlp/sg8/json.jsp", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          cmd: "loginjs",
          u: username,
          p: password,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      handleResponse(data);
    } catch (error) {
      setErrorMessage(
        "There was a problem with the login operation. Please try again."
      );
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleResponse = (data) => {
    if (data.s === "success") {
      if (rememberMe) {
        localStorage.setItem("username", data.username);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("rememberMe", "true");
      }
      localStorage.setItem("username", data.username);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("rememberMe", "true");
      setUsername(data.username);
      setIsLoggedIn(true);
      setErrorMessage("");
      /* Calumpit Filter Code */
      window.location.href = "/";
    } else {
      setErrorMessage(data.emsg || "Login failed. Please try again.");
      console.error("Login failed:", data);
    }
  };

  const handleOtpClick = () => {
    setShowOtpForm(true);
    setCloseOtpForm(true);
  };

  const handleCloseOtpClick = () => {
    setShowOtpForm(false);
    setCloseOtpForm(false);
    setOtpSuccess(false);
  };

  const handleInputChange = (e) => {
    setMobile(e.target.value);
  };

  const getOtp = async () => {
    try {
      const response = await axios.post(
        "https://sg8.casino/json/wlp/sg8/json.jsp",
        null,
        {
          params: {
            cmd: "getOTP",
            mobile: mobile,
          },
        }
      );
      if (response.data.s) {
        setOtpSuccess(true); // Show success message
      }
      console.log(response.data); // Handle the response as needed
    } catch (error) {
      console.error("Error getting OTP:", error);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://sg8.casino/json/wlp/sg8/json.jsp",
        null,
        {
          params: {
            cmd: "changepasswordWithOTP",
            mobile: mobile,
            otp: otp,
            password: newPassword,
          },
        }
      );

      if (response.data.s) {
        setErrorMessage(
          "Password changed successfully, please proceed to login with your new password."
        );
        setOtpSuccess(false);
        setShowOtpForm(false);
        setCloseOtpForm(false);
      } else {
        setErrorMessage(response.data.e || "Error changing password.");
      }
    } catch (error) {
      setErrorMessage(
        "There was an error changing the password. Please try again."
      );
      console.error("Error changing password:", error);
    }
  };

  return (
    <div>
      {errorMessage && (
        <p
          style={{ position: "relative", fontSize: "0.8em", color: "#d3d3d3" }}
        >
          {errorMessage}
        </p>
      )}
      <form className="form-login" onSubmit={handleLogin}>
        <label>Username/Mobile No.</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username or Mobile No."
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMe}
          />
          Remember Me
        </label>
        <button type="submit">Login</button>
        <button
          type="button"
          className="forgetpw-trigger"
          onClick={handleOtpClick}
        >
          Forgot Password or Username?
        </button>
      </form>
      {showOtpForm && (
        <div className="formotp">
          <span className="notice">
            Notice: Please input your registered mobile number that starts with
            '0'.
          </span>
          <label>Registered Number.</label>
          <input
            type="text"
            value={mobile}
            onChange={handleInputChange}
            placeholder="Enter mobile number"
          />
          <button onClick={getOtp}>Submit</button>
        </div>
      )}
      {otpSuccess && (
        <div className="success-otp">
          <label className="otplabel">OTP</label>
          <input
            className="otpinput"
            type="text"
            placeholder="OTP"
            autoComplete="off"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <label>New Password</label>
          <input
            type="password"
            placeholder="New Password"
            autoComplete="off"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label>Confirm New Password</label>
          <input
            type="password"
            placeholder="Confirm New Password"
            autoComplete="off"
            required
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <button onClick={handleChangePassword}>Change Password</button>
        </div>
      )}
      {closeOtpForm && (
        <button className="otp-close" onClick={handleCloseOtpClick}>
          ✖
        </button>
      )}
    </div>
  );
};

export default Login;
