import React from "react";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const currentPath = window.location.pathname;
const currentDomain = window.location.origin;

export default function TermsAndCondition() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino Terms and Conditions | User Agreement 2024</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Review the 2024 terms and conditions for SG8 Casino. Understand our user agreement, policies, and guidelines for a safe and fair gaming experience. Read now."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <section className="termsCondition">
        <div className="page-headlines-container">
          <div className="pageHeaderText">
            <h1>Terms and Conditions</h1>
          </div>
        </div>
        <p>
          {" "}
          All betting information supplied by SG8 is administered in good faith.
          Please take a moment to read carefully.{" "}
        </p>
        <p>
          {" "}
          The following are the Terms and Conditions governing the use of this
          website and the services provided by SG8. By accessing any page of
          this website and/or using the services, the customer agrees to be
          bound by the Terms and Conditions without limitation or qualification:
        </p>
        <ol>
          <li>To provide you with services and</li>
          <li>To comply with legal and regulatory obligations.</li>
        </ol>
        <p>
          {" "}
          If you do not accept any of the Terms and Conditions, do not attempt
          to use or continue using any of the services immediately.{" "}
        </p>
        <p>
          {" "}
          We reserve the right to change the Terms and Conditions without prior
          notice.{" "}
        </p>
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>General</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                The general guidelines for SG8’s Terms and Conditions entail the
                following:
              </p>
              <ul>
                <li>
                  This end-user agreement (the “Agreement”) should be read by
                  you (the “User” or “you”) in its entirety prior to your use of
                  SG8’s service or products. Please note that the agreement
                  constitutes a legally binding agreement between you and SG8.
                </li>
                <li>
                  These Terms and Conditions come into force as soon as you
                  complete the registration process, which includes checking the
                  box accepting these Terms and Conditions and successfully
                  creating an account. By using any part of the Website
                  following account creation, you agree to these Terms and
                  Conditions applying to the use of the Website.{" "}
                </li>
                <li>
                  You must read these Terms and Conditions carefully in their
                  entirety before creating an account. If you do not agree with
                  any provision of these Terms and Conditions, you must not
                  create an account or continue to use the website.
                </li>
                <li>
                  {" "}
                  SG8 can only be accessed and used by SG8.casino registered
                  players within the Philippine jurisdiction. Valid SG8 members
                  are those who have no pending exclusion orders from PAGCOR.{" "}
                </li>
                <li>
                  {" "}
                  SG8 will not accept bets emanating outside the Philippines and
                  will not be accessible in any way whatsoever outside the
                  Philippines{" "}
                </li>
                <li>
                  {" "}
                  SG8 members are only allowed to open one (1) online gaming
                  account for personal use.{" "}
                </li>
                <li>
                  {" "}
                  The online gaming account of the SG8 member shall not be used
                  for or on behalf of another person. The SG8 member shall not
                  allow or authorize any third party to access or use his or her
                  account. A SG8 member shall, at all times, be responsible for
                  any activity on his or her account.{" "}
                </li>
                <li>
                  {" "}
                  SG8 members are expected to never share their PIN with anyone.
                  Any activities or transactions in your account are considered
                  valid if the SG8 Member ID number and PIN used are correct.{" "}
                </li>
                <li>
                  {" "}
                  In certain circumstances, as provided in the SG8 Gaming
                  Implementing Rules and Guidelines, conduct verification of
                  your identity or the sources of funds deposited.{" "}
                </li>
                <li>
                  {" "}
                  Philippine government officials and employees, together with
                  their spouses, children, and parents, members of the
                  Philippine military, and law enforcement agencies, are not
                  allowed to play and place bets with SG8.{" "}
                </li>
                <li>
                  {" "}
                  By clicking the “I Agree” button, the player acknowledges and
                  agrees to abide by all Terms and Conditions laid out by SG8.{" "}
                </li>
                <li>
                  {" "}
                  SG8 shall not be responsible for any failure in hardware
                  and/or internet connection that will cause the placing of an
                  incorrect bet.{" "}
                </li>
                <li>
                  {" "}
                  All game contents offered by SG8 have been approved by PAGCOR.{" "}
                </li>
                <li>
                  {" "}
                  We reserve the right to make amendments to these Terms and
                  Conditions at any time and without advance notice. If we make
                  such amendments, we may take appropriate steps to bring such
                  changes to your attention (such as by email or placing a
                  notice in a prominent position on the website, together with
                  the amended terms and conditions), but it shall be your sole
                  responsibility to check for any amendments, updates, and/or
                  modifications. Your continued use of SG8 services and website
                  after any such amendment to the Terms and Conditions will be
                  deemed to be your acceptance and agreement to be bound by such
                  amendments, updates, and/or modifications.{" "}
                </li>
                <li>
                  {" "}
                  All players are required to read and accept the Terms and
                  Conditions during registration. A player’s violation of any
                  provisions of the Terms and Conditions will be a ground for
                  forfeiture of funds thereof, as the case may be. Forfeited
                  funds will not be returned to players.{" "}
                </li>
                <li>
                  {" "}
                  By clicking the “I Agree” button if and where provided and/or
                  using the Service, you consent to the terms and conditions set
                  forth in this Agreement.{" "}
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Collected Information</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Only registered players of SG8 are allowed to avail of the
                remote gaming platform service. New players are required to
                register online via sg8.casino.
              </p>
              <p>
                Upon registration, players shall provide accurate
                identification, a valid address, an email, a contact number, and
                other details.
              </p>
              <p>
                Accounts with incorrect and/or inaccurate identification and/or
                other details shall be terminated.
              </p>
              <p>
                SG8 shall have the absolute authority to close any player
                account, with or without cause. In such an event, non-erring
                accounts shall receive the balance of their funds.
              </p>
              <p>
                Funds maintained in the accounts of players shall not earn
                interest.
              </p>
              <p>
                Players may, at any time, withdraw from their accounts, provided
                that payments have been confirmed.
              </p>
              <p>
                A player shall only be allowed one (1) account. Multiple
                accounts are strictly prohibited. Players with multiple accounts
                will be frozen and closed. Funds in such multiple accounts shall
                be forfeited. Any player found guilty of fraud shall be liable
                for any damage incurred as a result thereof.
              </p>
              <p style={{ fontWeight: "bold" }}>
                SG8 will collect and keep the following mandatory information
                about all its players:
              </p>
              <ol>
                <li>Full Name</li>
                <li>Date of Birth</li>
                <li>Place of Birth</li>
                <li>
                  {" "}
                  Financial Data includes bank account information and
                  information relating to your financial position.{" "}
                </li>
                <li>Current Address</li>
                <li>Permanent Address</li>
                <li>Contact Information</li>
                <li>Nationality</li>
                <li>Nature of Work</li>
                <li>Source of Income</li>
                <li>
                  One (1) valid government-issued identification (ID) card
                </li>
              </ol>
              <p>
                Only persons twenty-one (21) years of age and older, and not
                among the banned personalities as provided under Memorandum
                Circular No. 6, series as of 2016, of the Office of the
                President, shall strictly be allowed to register and avail of
                the services offered by SG8. All players will be required to
                declare that (i) he/she is at least 21 years old and above and
                (ii) that he/she is not a government official or employee
                connected directly with the operation of the government or any
                of its agencies, a member of the Armed Forces of the
                Philippines, including the Army, Navy, Air Force, or the
                Philippine National Police.
              </p>
              <p style={{ fontWeight: "bold" }}>Players are prohibited from:</p>
              <ol>
                <li>
                  Enrolling, playing, and betting on behalf of another person or
                  third party;
                </li>
                <li>Depositing funds from any criminal activity; and</li>
                <li>
                  Using a SG8 account for money laundering or any other illegal
                  purpose or activity
                </li>
              </ol>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Deposit Policy</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {" "}
                SG8 will only utilise AMLC-compliant channels of funding, such
                as over-the-counter and online banking, payment and e-Wallet
                systems, and other legitimate money transfer services. Only
                payment systems or money transfer services accredited, approved,
                or registered with the Banko Sentral ng Pilipinas (BSP) shall be
                considered legitimate and shall be utilised.{" "}
              </p>
              <p>
                {" "}
                Integrated payment gateways that may be utilised by SG8 will be
                used exclusively for purposes of account funding and withdrawal
                and shall not perform player registration functions.{" "}
              </p>
              <p>
                {" "}
                Player registration procedures will not be performed by any
                payment gateway provider, such as Operators of Payment Systems
                (OPS) or any other money transfer service. However, a link
                feature may be implemented where a prospective player will be
                redirected to SG8’s existing player registration portal to
                undergo the player registration procedure therein.{" "}
              </p>
              <p>
                {" "}
                Players must provide an owned bank account or e-wallet under
                their own name.{" "}
              </p>
              <p>
                {" "}
                It is prohibited for multiple accounts to possess the same bank
                or e-wallet account name or number. If a bank account is already
                linked to an existing SG8 account, it cannot be used for a new
                registration.{" "}
              </p>
              <p>
                {" "}
                Deposits, withdrawals, and/or transfers from and to a payment or
                banking solution account that are not registered under the
                player’s account are strictly prohibited, and doing so will
                result in penalties.{" "}
              </p>
              <p>
                {" "}
                Fund sharing, or pasaload, between players is strictly
                prohibited.{" "}
              </p>
              <p>
                {" "}
                Players are expected to input accurate banking details. SG8 will
                not be liable for any losses incurred as a result of incorrect
                banking information arising from players’ negligence.{" "}
              </p>
              <p>
                {" "}
                All newly registered players will be required to make an initial
                deposit of PHP 1,000. The minimum deposit for subsequent
                transactions thereafter will be PHP 500.{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Withdrawal Policy</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {" "}
                SG8 will only utilise AMLC-compliant channels of withdrawal,
                such as over-the-counter and online banking, payment/e-Wallet
                systems, and other legitimate money transfer services. Only
                payment systems or money transfer services accredited, approved,
                or registered with the Banko Sentral ng Pilipinas (BSP) shall be
                considered legitimate and shall be utilised.{" "}
              </p>
              <p>
                {" "}
                There is a 1x rollover requirement before any withdrawal of
                credits can be done.{" "}
              </p>
              <p> Example:</p>
              <ul>
                <li>Player deposits PHP 1,000</li>
                <li>
                  The player is required to accumulate a total turnover of at
                  least PHP 1,000 prior to withdrawing any credits.
                </li>
                <li>
                  Players can always feel free to contact our customer service
                  representatives should they require any assistance.
                </li>
              </ul>
              <p>
                {" "}
                Players can check their deposit, withdrawal, and turnover data
                under “Dashboard”.{" "}
              </p>
              <p>
                {" "}
                SG8 provides real-time processing of withdrawal requests. For
                more information regarding our maximum withdrawal limit, please
                contact our friendly customer service representatives.{" "}
              </p>
              <p>
                {" "}
                Deposits, withdrawals, and/or transfers from and to a payment or
                banking solution account that are not registered under the
                player’s account are strictly prohibited, and doing so will
                result in penalties.{" "}
              </p>
              <p>
                {" "}
                For any further assistance or inquiries, you may click the chat
                box tool at the bottom right of the page to speak to our
                friendly CSRs.{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Player Obligations</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {" "}
                Prior to your use of the service and on an ongoing basis, you
                represent, warrant, covenant, and agree that:{" "}
              </p>
              <p>
                {" "}
                You are over 21 or such other legal age of majority as
                determined by any laws that are applicable to you, whichever age
                is greater;{" "}
              </p>
              <p>
                {" "}
                You have full capacity to enter into a legally binding agreement
                with us, and you are not restricted by any form of limited legal
                capacity.{" "}
              </p>
              <p>
                {" "}
                You are not diagnosed or classified as a compulsive or problem
                gambler.{" "}
              </p>
              <p>
                {" "}
                You are accessing SG8 from a jurisdiction in which it is legal
                to do so.{" "}
              </p>
              <p>
                {" "}
                All information that you provide to us during the term of
                validity of this agreement is true, complete, and correct, and
                you shall immediately notify us of any change in such
                information.{" "}
              </p>
              <p>
                {" "}
                You will not use our services while located in any jurisdiction
                that prohibits the placing and/or accepting of bets online
                and/or playing casino and/or live games and/or sportsbooks;{" "}
              </p>
              <p>
                {" "}
                Your use of the service is at your sole discretion and risk.{" "}
              </p>
              <p>
                {" "}
                You participate in the games on your own behalf and not on
                behalf of any other person.{" "}
              </p>
              <p>
                {" "}
                You are solely responsible for reporting and accounting for any
                taxes applicable to you under relevant laws for any winnings
                that you receive from us.{" "}
              </p>
              <p>
                {" "}
                There is a risk of losing money when using the service, and SG8
                is not responsible for your losses.{" "}
              </p>
              <p>
                {" "}
                In relation to deposits and withdrawals of funds into and from
                your SG8 Account, you shall only use currency that is valid and
                lawfully belongs to you;{" "}
              </p>
              <p>
                {" "}
                You only have one account with us, and you agree not to open
                multiple accounts with us.{" "}
              </p>
              <p>
                {" "}
                You acknowledge and accept that we reserve the right to detect
                and prevent the use of prohibited techniques, including but not
                limited to fraudulent transaction detection, automated
                registration and signup, gameplay, and screen capture
                techniques. These steps may include, but are not limited to,
                examination of players device properties, detection of
                geo-location and IP masking, transactions, and blockchain
                analysis;{" "}
              </p>
              <p>
                {" "}
                The telecommunications networks and Internet access services
                required for you to access and use the service are entirely
                beyond the control of SG8, and SG8 shall have no liability
                whatsoever for any outages, slowness, capacity constraints, or
                other deficiencies affecting the same.{" "}
              </p>
              <p>
                {" "}
                Any other obligations and guidelines that are set forth by the
                Philippine Amusement and Gaming Corporation (PAGCOR) and/or any
                other regulating authorities in the Philippines{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Restriction / Suspension / Termination of Online Gaming Account
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                SG8 reserves the right to restrict, suspend, or terminate your
                account access and use at any time and at their discretion,
                pending verification of your identity and source of funds.
              </p>
              <p>
                {" "}
                SG8, at any time, has the right to suspend or terminate, without
                prior notice, the online gaming account of any SG8 who:
              </p>
              <ul>
                <li>
                  Allowed, whether intentionally or not, another person to use
                  its online gaming account;
                </li>
                <li>Attempted to defraud the system;</li>
                <li>
                  Engaged in any fraudulent, irregular, or criminal activities,
                  such as but not limited to collusion, cheating, money
                  laundering, etc.;
                </li>
                <li>Violated any of these terms and conditions;</li>
                <li>
                  Violated the regulations set by PAGCOR or Silver Goose 8
                  policies and regulations.
                  <ul>
                    <li>
                      The SG8 Programme membership shall also be suspended or
                      terminated accordingly.
                    </li>
                    <li>
                      Given that SG8 is integrated with the Philippine Amusement
                      and Gaming Corporation’s (PAGCOR) third-party audit
                      platform, upon termination of a player’s SG8 online gaming
                      account and membership, SG8 may endorse the player to
                      PAGCOR for further action.
                    </li>
                  </ul>
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Bonuses and Promotions</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {" "}
                SG8, at its sole discretion, might offer from time to time a
                number of bonuses and promotions.{" "}
              </p>
              <p>
                {" "}
                Each of the bonuses and promotions has its own set of terms and
                conditions and individual requirements, if any. By partaking in
                a bonus or promotion, you are automatically agreeing to the
                applicable terms and conditions.{" "}
              </p>
              <p>
                {" "}
                SG8 reserves the right to remove bonus credits from all inactive
                accounts or accounts that are identified as “Promotion/Bonus
                Hunters”.{" "}
              </p>
              <p>
                {" "}
                SG8 reserves the right to void all bonuses that have not been
                claimed within the claiming period, or 60 calendar days,
                whichever is shorter.{" "}
              </p>
              <p>
                {" "}
                SG8 reserves the right to cancel any bonus or promotion at its
                sole discretion.{" "}
              </p>
              <p>
                {" "}
                SG8 reserves the right to amend without notice from any player
                any bonuses or promotions for SG8.{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>
    </>
  );
}
