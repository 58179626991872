import React, { useState, useEffect } from 'react';
import { apiJSONURL } from './LiveCasino';
import axios from 'axios';
import qs from 'qs';

function Withdraw() {

  const [userData, setUserData] = useState({ userId: '', balance: null });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const username = localStorage.getItem('username');

    if (username) {
      const fetchBalance = async () => {
        try {
          const response = await fetch(`${apiJSONURL}?cmd=getBalance&u=${username}`);
          const data = await response.json();
          const balance = data.balance;
          setUserData(prevState => ({ ...prevState, userId: username, balance }));
        } catch (error) {
          console.error('Error fetching user balance:', error);
        }
      };

      const fetchRollover = async () => {
        try {
          const response = await fetch(`${apiJSONURL}?cmd=getRemainingRollover&u=${username}`);
          const data = await response.json();
          const rollover = data.rollover;
          console.log('Rollover data:', rollover);
          setUserData(prevState => ({ ...prevState, userId: username, rollover }));
        } catch (error) {
          console.error('Error fetching user rollover:', error);
        }
      };

      fetchBalance();
      fetchRollover();
    }
  }, []);

    const GcashBtn = <div id="GcashBtn"><img src="/images/gcash-icon.webp" alt="QRPH"/>Gcash</div>;
    const eWalletBtn = <div id="eWalletBtn"><img src="/images/ewallet-icon.webp" alt="PayMaya"/>eWallet</div>;
    const BankBtn = <div id="FortunePayBtn"><img src="/images/bank-icon.webp" alt="FortunePay"/>Bank Account</div>;

    const [visibleId, setVisibleId] = useState('withdrawalFormGcash'); // State to track which element should be visible
  
    // Function to handle click event on deposit links
    const handleWithdrawClick = (id) => {
      setVisibleId(id); // Update the state to show the corresponding element
      setAlertMessage('');
      setMac('');
      setMan('');
      setAmount('');
    };

    const [amt, setAmount] = useState('');
    const [mac, setMac] = useState('');
    const [man, setMan] = useState('');
    const [mbc, setMbc] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleGcashSubmit = async () => {
      const username = localStorage.getItem('username');
      const amount = parseFloat(amt);
      const accountno = mac;
      setLoading(true);
      
      // Check if any required field is empty or NaN
      if (!username || isNaN(amount) || isNaN(accountno)) {
          setAlertMessage('Please complete the withdrawal form.');
          return; // Don't proceed further
      }
      try {
        const response = await axios.post('https://sg8.casino/json/wlp/json/index.jsp', 
          qs.stringify({
            cmd: 'withdrawal-request',
            method:'GCASH',
            mac: accountno,
            u: username,
            amt: amount,
          }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          if (response.data.smsg) {
            setAlertMessage(response.data.smsg);
          } else if (response.data.emsg) {
              setAlertMessage(response.data.emsg);
          }
        } catch (error) {
            console.error('API call error:', error);
            setAlertMessage('An unexpected error occurred. Please contact support for assistance.');
        } finally {
          setLoading(false);
        }
    };

    const handleEWalletSubmit = async () => {
      const username = localStorage.getItem('username');
      const amount = parseFloat(amt);
      const accountno = mac;
      
      // Check if any required field is empty or NaN
      if (!username || isNaN(amount) || isNaN(accountno) || !mbc) {
          setAlertMessage('Please complete the withdrawal form.');
          return; // Don't proceed further
      }
    
      try {
        const response = await axios.post('https://sg8.casino/json/wlp/json/index.jsp', 
          qs.stringify({
            cmd: 'withdrawal-request',
            method:'EWALLET',
            mbc: mbc,
            mac: accountno,
            u: username,
            amt: amount,
          }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          console.log('API response:', response);
          if (response.data.smsg) {
            setAlertMessage(response.data.smsg);
          } else if (response.data.emsg) {
              setAlertMessage(response.data.emsg);
          }
        } catch (error) {
            console.error('API call error:', error);
            setAlertMessage('An unexpected error occurred. Please contact support for assistance.');
        }
    };

    const handleBankSubmit = async () => {
      const username = localStorage.getItem('username');
      const amount = parseFloat(amt);
      const accountno = mac;
      
      // Check if any required field is empty or NaN
      if (!username || isNaN(amount) || isNaN(accountno) || !mbc || !man) {
          setAlertMessage('Please complete the withdrawal form.');
          return; // Don't proceed further
      }
  
      try {
          const response = await axios.post('https://sg8.casino/json/wlp/json/index.jsp', 
              qs.stringify({
                  cmd: 'withdrawal-request',
                  method:'Bank',
                  man: man,
                  mac: accountno,
                  mbc: mbc,
                  u: username,
                  amt: amount,
              }), {
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                  }
              });
  
          console.log('API response:', response);
          
          if (response.data.smsg) {
            setAlertMessage(response.data.smsg);
          } else if (response.data.emsg) {
              setAlertMessage(response.data.emsg);
          }
      } catch (error) {
          console.error('API call error:', error);
          setAlertMessage('An unexpected error occurred. Please contact support for assistance.');
      }
  };

    return (
      <>
        <div className="account-container">
          <div className="page-headlines-container">
            <div className="page-h2-text-style">
              <h2>Withdrawal Request</h2><span>Get our Best Deals from SG8.Casino</span>
            </div>
          </div>
          <p className="remainingrollover" style={{ display: userData.rollover > 0 ? 'block' : 'none' }}>Remaining Rollover: <span id="remainingRollover-amt">{userData.rollover}</span></p>
        <div className='withdraw-container'>
          <div className="withdraw-nav nav-tabs">
            <a className={`${visibleId === 'withdrawalFormGcash' ? 'active' : ''}`} onClick={() => handleWithdrawClick('withdrawalFormGcash')}>{GcashBtn}</a>
            <a className={`${visibleId === 'withdrawalFormEWallet' ? 'active' : ''}`} onClick={() => handleWithdrawClick('withdrawalFormEWallet')}>{eWalletBtn}</a>
            <a className={`${visibleId === 'withdrawalFormBank' ? 'active' : ''}`} onClick={() => handleWithdrawClick('withdrawalFormBank')}>{BankBtn}</a>
          </div>
          <div className='all-form-container'>
          {visibleId === 'withdrawalFormBank' && <form id="withdrawalFormHelp2Pay">
            <div className="form-title">{BankBtn}</div>
              <div className="row">
                  <div className="hLabel">Bank</div>
                    <div className="input-container"><select value={mbc} onChange={(e) => setMbc(e.target.value)} name="mbc" className="form-control">
                      <option value="">Select Bank</option>
                      <option value="BDO">Banco de Oro</option>
                      <option value="AUB">Asia United Bank</option>
                      <option value="BPI">Bank of the Philippine Islands</option>
                      <option value="UBP">Union Bank Of The Philippines</option>
                      <option value="CBC">China Banking Corporation(China Bank)</option>
                      <option value="LBP">Land Bank of the Philippines</option>
                      <option value="BOC">Bank Of Commerce</option>
                      <option value="EQB">Equicom Savings Bank</option>
                      <option value="PNB">Philippine National Bank</option>
                      <option value="PTC">Philippine Trust Company</option>
                      <option value="PVB">Philippine Veterans Bank</option>
                      <option value="UCPB">United Coconut Planters Bank (UCPB)</option>
                      <option value="IBI">ISLA Bank</option>
                      <option value="MET">Metrobank</option>
                      <option value="MSB">Malayan Bank</option>
                      <option value="ONB">One Network Bank</option>
                      <option value="PAR">Partner Rural Bank(Cotabato) Inc</option>
                      <option value="PBB">Philippine Business Bank</option>
                      <option value="PNS">PNB Savings Bank</option>
                      <option value="PSB">Philippine Savings Bank</option>
                      <option value="QRB">Quezon Capital Rural Bank</option>
                      <option value="SBC">Security Bank</option>
                      <option value="SSB">Sun Savings Bank</option>
                      <option value="BMB">Bangko Mabuhay</option>
                      <option value="CSB">Citystate Savings Bank</option>
                      <option value="DBI">Dungganon Bank</option>
                      <option value="EWR">East West Rural Bank (Green Bank)</option>
                      <option value="WDB">Wealth Development Bank</option>
                      <option value="RCBC">Rizal Commercial Banking Corp. (RCBC)</option>
                      <option value="RSB">Robinsons Bank</option>
                      <option value="CRD">CARD Bank</option>
                      <option value="CTBC">Ctbc Bank (Philippines) Corp.</option>
                      <option value="EWB">East West Banking Corporation</option>
                      <option value="SBA">Sterling Bank Of Asia</option>
                      <option value="CBS">China Bank Savings, Inc</option>
                      <option value="DBP">Development Bank of the Philippines</option>
                      <option value="JPM">JP Morgan Chase Bank, N.A.</option>
                      <option value="BPIDB">BPI Direct BanKO, Inc., A Savings Bank</option>
                      <option value="RSBI">Rcbc Savings Bank Inc.</option>
                      <option value="CEBRUR">Cebuana Lhuillier Rural Bank, Inc.</option>
                      <option value="PRB">Producers Bank</option>
                      <option value="QCB">Queen City Development Bank, Inc.</option>
                      <option value="ING">ING Bank N.V.</option>
                      <option value="SPY">Starpay Corporation</option>
                      <option value="YUANSB">Yuanta Savings Bank</option>
                      <option value="UCBSB">UCPB Savings bank</option>
                      <option value="BRB">Binangonan Rural Bank (BRBDigital)</option>
                      <option value="ABP">AllBank Inc.</option>
                      <option value="RBG">RURAL BANK OF GUINOBATAN, INC.</option>
                      <option value="CMG">CAMALIG BANK</option>
                      <option value="MBP">Maybank Philippines</option>
                      <option value="PBCOM">Philippine Bank of Communications</option>
                      <option value="CIMB">CIMB Bank Philippines, Inc.</option>
                      <option value="GTB">GoTyme Bank Corporation</option>
                  </select>
                  </div>
              </div>
              <div className="row">
                  <div className="hLabel">Bank Account Name</div>
                  <div className="input-container">
                    <input className="form-control" value={man} onChange={(e) => setMan(e.target.value)} autoComplete="off" name="man" type="text" placeholder="Bank Account Name" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Bank Account Name" />
                  </div>
              </div>
              <div className="row">
                  <div className="hLabel">Bank Account Number</div>
                  <div className="input-container">
                  <input 
                      className="form-control"  
                      value={mac} 
                      onChange={(e) => setMac(e.target.value)} 
                      title="" 
                      autoComplete="off" 
                      name="mac" 
                      type="text" 
                      placeholder="Bank Account Number" 
                      data-trigger="focus" 
                      data-toggle="popover" 
                      data-placement="bottom" 
                      data-content="" 
                      data-original-title="Bank Account Number" 
                  />
                  </div>
              </div>
              <div className="row">
                  <div className="hLabel">Balance</div>
                  <div className="input-container"><div id="withdrawal-help2pay-balance" className="userBalance">{userData.balance !== null ? userData.balance : 'Loading...'}</div></div>
              </div>
              <div className="row">
                  <div className="hLabel">Withdrawal Amount</div>
                  <div className="input-container">
                    <input id="withdrawal-help2pay-amount" className="form-control" autoComplete="off" max="1000000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:1,000,000"  value={amt} onChange={(e) => setAmount(e.target.value)}/>
                  </div>
              </div>
              <div className="row">
                  <div className="input-container"  colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                      <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                      <button id="btn-help2pay-submit" className="yellow-btn" type="button" onClick={handleBankSubmit}>SUBMIT</button>
                  </div>
              </div>
              </form>}
            
          {visibleId === 'withdrawalFormGcash' && <form id="withdrawalFormGcash">
            <div className="form-title">{GcashBtn}</div>
            <div className="row">
              <div className="hLabel">Phone Number</div>
              <div className="input-container">
              <input 
                      className="form-control"  
                      value={mac} 
                      onChange={(e) => setMac(e.target.value)} 
                      title="" 
                      autoComplete="off" 
                      name="mac" 
                      type="text" 
                      placeholder="Phone Number" 
                      data-trigger="focus" 
                      data-toggle="popover" 
                      data-placement="bottom" 
                      data-content="" 
                      data-original-title="Phone Number" 
                  />
              </div>
            </div>
            <div className="row">
              <div className="hLabel">Balance</div>
              <div className="input-container"><div id="withdrawal-gcash-balance" className="userBalance">{userData.balance !== null ? userData.balance : 'Loading...'}</div></div>
            </div>
            <div className="row">
              <div className="hLabel">Withdrawal Amount</div>
              <div className="input-container">
                <input id="paycools-amount" className="form-control txt-amount" autoComplete="off" max="30000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:50,000" value={amt} onChange={(e) => setAmount(e.target.value)} />
              </div>
            </div>
            <div className="row">
              <div className="input-container" colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                <button className="yellow-btn" type="button" onClick={handleGcashSubmit}>SUBMIT</button>
              </div>
            </div>
            </form>}

          {visibleId === 'withdrawalFormEWallet' && <form id="withdrawalFormEWallet">
            <div className="form-title">{eWalletBtn}</div>
              <div className="row">
                <div className="hLabel" id="payCoolsWalletLabel">eWallet</div>
                  <div className="input-container">
                    <select id="eWallet" name="mbc" className="form-control" value={mbc} onChange={(e) => setMbc(e.target.value)}>
                      <option value="">Select eWallet</option>
                      <option value="PAYMAYA">MAYA</option>
                      <option value="GRABPAY">GRABPAY</option>
                      <option value="SHOPEE">SHOPEE</option>
                      <option value="COINS">COINS</option>
                      <option value="FORTUNEPAY">FORTUNEPAY</option>
                      <option value="OMNIPAY">OMNIPAY</option>
                    </select>
                  </div>
              </div>
              <div className="row">
                  <div className="hLabel">Mobile Number</div>
                  <div className="input-container">
                  <input className="form-control" value={mac} onChange={(e) => setMac(e.target.value)} autoComplete="off" name="mac" type="text" placeholder="Mobile Number" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Mobile Number" />
                  </div>
              </div>
              <div className="row">
                  <div className="hLabel">Balance</div>
                  <div className="input-container"><div id="withdrawal-paycools-balance" className="userBalance">{userData.balance !== null ? userData.balance : 'Loading...'}</div></div>
              </div>
              <div className="row">
                  <div className="hLabel">Withdrawal Amount</div>
                  <div className="input-container">
                    <input id="withdrawal-paycools-amount" className="form-control" autoComplete="off" max="1000000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:50,000" value={amt} onChange={(e) => setAmount(e.target.value)}/>
                  </div>
              </div>
              <div className="row">
                  <div className="input-container"  colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button id="btn-paycools-submit" className="yellow-btn" type="button" onClick={handleEWalletSubmit}>SUBMIT</button>
              </div>
              </div>
              </form>}
            </div>
            {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
          </div>
        </div>
    </>
      
  )
}

export default Withdraw