import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_GA;
ReactGA.initialize(trackingId);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

export default trackPage;
