import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import ProviderListHome from "../../snippets/ProviderListHome";
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';
import { useTranslation } from 'react-i18next';

function Slots() {
    const [sliderCurrentPage, setSliderCurrentPage] = useState('all');
    const [bannerCurrentPage, setBannerCurrentPage] = useState('all');
    const { t } = useTranslation();
    const slotsBox = [
        {id: 'rtg', bgImg: "/images/slots/hero/rtg-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/rtg-logo-small.webp", btn: "/slots/rtg/", newGame: false, topGame: true},
        {id: 'spades', bgImg: "/images/slots/hero/spadegaming-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/spadegaming-logo-small.webp", btn: "/slots/spadegaming/", newGame: false, topGame: false},
        {id: 'jili', bgImg: "/images/slots/hero/jili-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/jili-logo-small.webp", btn: "/slots/jili/", newGame: false, topGame: true},
        {id: 'bng', bgImg: "/images/slots/hero/bng-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/bng-logo-small.webp", btn: "/slots/bng/", newGame: false, topGame: false},
        {id: 'playstar', bgImg: "/images/slots/hero/playstar-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/playstar-logo-small.webp", btn: "/slots/playstar/", newGame: false, topGame: false},
        {id: 'playson', bgImg: "/images/slots/hero/playson-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/playson-logo-small.webp", btn: "/slots/playson/", newGame: false, topGame: false},
        {id: 'netent', bgImg: "/images/slots/hero/netent-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/netent-logo-small.webp", btn: "/slots/netent/", newGame: false, topGame: false},
        {id: 'fachai', bgImg: "/images/slots/hero/fachai-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/fachai-logo-small.webp", btn: "/slots/fachai/", newGame: false, topGame: false},
        {id: 'pgsoft', bgImg: "/images/slots/hero/pgsoft-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/pg-soft-logo-small.webp", btn: "/slots/pgsoft/", newGame: false, topGame: false},
        {id: 'ygg', bgImg: "/images/slots/hero/ygg-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/ygg-logo-small.webp", btn: "/slots/ygg/", newGame: true, topGame: false},
        {id: 'redtiger', bgImg: "/images/slots/hero/redtiger-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/red-tiger-logo-small.webp", btn: "/slots/redtiger/", newGame: false, topGame: false},
        {id: 'cq9', bgImg: "/images/slots/hero/cq9-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/cq9-logo-small.webp", btn: "/slots/cq9/", newGame: false, topGame: false},
    ];

    // Sort the slotsBox array to put top games first, then new games, then alphabetically by 'id'
    slotsBox.sort((a, b) => {
        if (a.newGame === b.newGame) {
            if (a.topGame === b.topGame) {
                return a.id.localeCompare(b.id);
            }
            return a.topGame ? -1 : 1;
        }
        return a.newGame ? -1 : 1;
    });

    const slotItem = slotsBox.map((data) => (
        <div className='slotsBox' style={{ backgroundImage: `url(${data.bgImg})` }} key={data.id}>
            {data.topGame && <div className='top-label'>Top</div>}
            {data.newGame && <div className='new-label'>New</div>}
            <img src={data.logoImg} alt={`${data.id} logo`} />
            <a href={data.btn} className='yellow-btn slots-selector-btn'>{t('button.1')}</a>
        </div>
    ));

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino Online Slots | Best Slot Games 2024</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Discover the best online slot games of 2024 at SG8 Casino. Enjoy high-quality slots, innovative gameplay, and thrilling wins. Join now and start playing!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
                    <ProviderListHome/>
                    <div className='slotsProviderWrapper'>
                        {slotItem}
                    </div> 
                </div>
            </div>
            <div className="main-content-wrapper">
            <div className="text-content-area">
            </div>
            </div>
        </>
    );
}

export default Slots;
