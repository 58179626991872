import React from "react";
import { Helmet } from "react-helmet";
import SBLaunch from "../snippets/SBLaunch";
import PaymentMethod from "./../snippets/PaymentMethod";
import { currentPath, currentDomain } from "../App";
import SportsbookFooter from "../snippets/SportsbookFooter";
import { useTranslation } from 'react-i18next';
function Sports() {
  const gameId = "62001";
  const { t, i18n } = useTranslation();
  const openGameInNewWindow = () => {
    const launchURL = document.querySelector(".gameIframe")?.src;
    if (launchURL) {
      window.open(
        launchURL,
        "GameWindow",
        "width=800,height=600,resizable=yes,scrollbars=yes,status=yes"
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Premier Sportsbook Powered by Pinnacle | SG8 Casino</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Bet on your favorite sports with SG8 Casino's premier sportsbook, powered by Pinnacle. Enjoy competitive odds and a seamless betting experience."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1 className="sportsTitle">
                {t('sports.2')}
              </h1>

              <div className="liveCasino sports">
                <div className="liveCasinoContent">
                  <div className="liveContainer">
                    <img
                      className="liveCasinoLogo"
                      src="/images/livedealer/cmd-logo.png"
                      alt=""
                    />
                    <img
                      className="charMain"
                      src="/images/livedealer/cmd.jpg"
                      alt=""
                    />
                    
                    <div className="liveCasinoBtn sportsbtn">
                      <a href="/sportsbook/cmd368/" className="yellow-btn">
                        {t('button.1')}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="liveCasinoContent">
                  <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/sbobet-logo.png"
                    alt=""
                  />
                  <img
                    className="charMain"
                    src="/images/livedealer/sbobet.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a href="/sportsbook/sbobet/" className="yellow-btn">
                      {t('button.1')}
                    </a>
                  </div>
                </div>
                </div>
                <div className="liveCasinoContent">
                  <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/pinnacle-logo.png"
                    alt=""
                  />
                  
                  <img
                    className="charMain"
                    src="/images/livedealer/pinnacle.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a href="/sportsbook/pinnacle/" className="yellow-btn">
                      {t('button.1')}
                    </a>
                  </div>
                </div>
                </div>
                
              
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <SportsbookFooter/>
    </>
  );
}

export default Sports;
