import React from "react";
import { Helmet } from "react-helmet";
import PaymentMethod from "./../snippets/PaymentMethod";
import { currentPath, currentDomain } from "../App";
import { useTranslation } from "react-i18next";

function Pk10Keno() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>PK10 - Keno | SG8 Casino</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the ultimate PK10 experience!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <h1 className="sportsTitle pk10title">
            Start the Game
          </h1>
          <div className="pk10-dashboard">
            <div>
              <img src="/images/pk10/keno.jpg" alt="" />
              <div className="pk10Logo">
                <img src="/images/pk10/gameplay-logo.webp" alt="" />
              </div>
              <div>
                <a href="/pk10-keno/keno/" className="yellow-btn">PLAY NOW</a>
              </div>
            </div>
            <div>
              <img src="/images/pk10/pk10.jpg" alt="" />
              <div className="pk10Logo">
                <img src="/images/pk10/PK10-logo.png" alt="" />
              </div>
              <div>
                <a href="/pk10-keno/pk10/" className="yellow-btn">PLAY NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pk10Keno;
