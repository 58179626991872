import React from "react";
import { Helmet } from "react-helmet";
import PaymentMethod from "../snippets/PaymentMethod";
import { currentPath, currentDomain } from "../App";
import { useTranslation } from "react-i18next";
import Back from "./sportsbook/Back";

function Pk10() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>PK10 | SG8 Casino</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the ultimate PK10 experience!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <Back/>
          <div className="pk10-area">
            <div className="logo-Container">
              <div className="pk10-logo">
                <img src="/images/pk10/PK10-logo.png" alt="" />
              </div>
              <div className="liveCasinoBtn sportsbtn">
                <a href="#" className="yellow-btn">
                  {t("button.1")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pk10;
