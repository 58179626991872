import React, { useState, useEffect } from 'react';  
import Popup from 'reactjs-popup'; 

const PopUpModal = () => {
  const [open, setOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false); 
  const [showButton, setShowButton] = useState(false); 

  useEffect(() => {
    const hasModalBeenShown = sessionStorage.getItem('modalShown');
    const shouldShowButton = sessionStorage.getItem('showButton');

    if (!hasModalBeenShown) {
       setOpen(true);
    }

    if (shouldShowButton) {
      setShowButton(true);
    }

    if (open || secondOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; 
    }

    const handleUrlChange = () => {
    };

    window.addEventListener('popstate', handleUrlChange);
    window.addEventListener('hashchange', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      window.removeEventListener('hashchange', handleUrlChange);
      document.body.style.overflow = 'auto'; 
    };
  }, [open, secondOpen]);

  const handleLinkClick = (confirmAge = false) => {
    if (confirmAge) {
      sessionStorage.setItem('modalShown', 'true');
      setOpen(false);
     /* setSecondOpen(true); */
    } else {
      setOpen(false);
      window.location.href = 'https://www.google.com';
    }
  };

  // const handleDownload = () => {
  //   window.location.href = apk;
  //   setSecondOpen(false); 
  //   setShowButton(true);
  //   sessionStorage.setItem('showButton', 'true');
  // };

  // const handleSecondPopupClose = () => {
  //   setSecondOpen(false);
  //   setShowButton(true); 
  //   sessionStorage.setItem('showButton', 'true');
  // };

  // const handleLearnMoreClick = () => {
  //   setShowButton(true);
  //   sessionStorage.setItem('showButton', 'true');
  //   window.location.href = '/news/download-sg8-casino-mobile-app/';
  // };

  return (
    <>
      <Popup open={open} onClose={() => setOpen(false)} modal nested closeOnDocumentClick={false}>
        {close => (
          <div className="popUpModal modalWithBlur" style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}>
            <div className='popUpContent'>
              <div className="header">
                <img src="/images/SG8-logo.png" alt="SG8 Logo" /> 
              </div>
              <div className="content">
                <h2>AGE VERIFICATION</h2>
                <p>Please confirm that you are <strong>21 years of age</strong> or older to enter this site.</p>
                <div className='popUpBtn'>
                  <button onClick={() => handleLinkClick(true)} className='yellow-btn'>I am 21 years of age</button>
                  <button onClick={() => handleLinkClick(false)} className='yellow-btn'>NO</button>  
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>

      {/* <Popup open={secondOpen} onClose={handleSecondPopupClose} modal nested closeOnDocumentClick={false}>
        {close => (
          <div className="popUpModal modalWithBlur download-popup-message" style={{ backgroundImage: `url('/images/mobile-app-available.webp')` }}>
              <div className='download-btn-wrapper'>
                <div className=''>
                  <button onClick={handleDownload} className='yellow-btn download-btn-popup'>Download Now</button>
                  <a onClick={handleLearnMoreClick} className='download-page-btn'>Learn More</a>
                  <button onClick={handleSecondPopupClose} className='yellow-btn download-btn-close'><FontAwesomeIcon icon={faXmark} /></button>
                </div>
            </div>
          </div>
        )}
      </Popup>

      {showButton && (
        <div className="fixed-button">
          <div className="app-button">
              <a href={apk} style={{cursor: "pointer"}} target="_new" rel="noopener" className='download-btn-global'><img src="/images/download/download.png" alt="Download our App now!"/></a>
          </div>
        </div>
      )} */}
    </>
  );
};

export default PopUpModal;
