import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import {currentPath, currentDomain} from "../../App";

export default function PromotionPage() {
  const title = "Deposit Bonus";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("DepositBonus");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("DepositBonus");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8 Casino: 500THB More on First Deposit!</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8 Casino with our generous deposit bonus. 500THB More on First Deposit!. Claim your bonus today!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />

<div className="main-wrapper-promo">
<div className="main-content-area" >
        <h2>
        500THB More on First Deposit
        </h2>
<p>To welcome all our new members, SG8Bet is giving you a special bonus. All newly registered members will be able to get 500THB more upon their first deposit!</p><br/>
       
        <h2>For Example</h2>
        <ul>
          <li>Member deposited 1,200THB</li>
          <li>Member will receive 500THB Deposit Bonus</li>
          <li>Member's total credits will be 1,700THB</li>
        </ul>
<br/>
        <h2>Terms & Conditions</h2>
        <ul>
          <li>This bonus only applies to new members and 1st Deposit after account registration.</li>
          <li>Minimum Deposit amount to get this bonus is 1,000THB</li>
          <li>Members cannot withdraw the 500THB bonus credits.</li>
          <li>SG8Bet will deduct this 500THB bonus credit on your first withdrawal.</li>
        </ul>
      </div>
      </div>
      </div>
    </>
  );
}
