import MobileNavigation from "./snippets/MobileNavigation";
import ContactInfo from "./snippets/ContactInfo";
import { useTranslation } from 'react-i18next';
export const today = new Date();
export const apk = process.env.REACT_APP_APK;


function Footer() {
  const { t, i18n } = useTranslation();
  const handleLinkClick = () => {
    window.location.href = apk; 
  };
  const currentPage = window.location.pathname;
  return (
    <>
      <div id="content" className="site-content">
        <footer>
          <div className="sg8-footer-main">
            <div className="footer-c1">
              <div className="footer-flexbox">
                <p className="footer-text">{t('footer.2')}</p>
                <div className="complianceLogo">
                  <img src="/images/sg8-logo.png" className="pagcorLogo" alt="pagcor" />
                  <img src="/images/gli-logo.webp" className="gameresponsibly" alt="game responsibly" />
                  <img src="/images/gli-cert.webp" className="gameresponsibly" alt="game responsibly" />
                </div>
              </div>
            </div>
          </div>
          <div className="sg8-footer-bottom">
            <div className="tagline-footer">
              <p>
                © {today.getFullYear()} {t('footer.3')}
              </p>
            </div>
          </div>
          <MobileNavigation />
        </footer>
      </div>
    </>
  );
}

export default Footer;