import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';

const rtgFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'featured', label: 'Featured' },
    { value: 'slots', label: 'Slots' },
    { value: 'table', label: 'Table' },
    { value: 'videopoker', label: 'Video Poker' },
    { value: 'arcade', label: 'Arcade' },
    { value: 'jackpotslots', label: 'Jackpot Slots' }
];

function MannaPlay() {
    const [currentFilter, setCurrentFilter] = useState('all');
    const [sliderCurrentPage, setSliderCurrentPage] = useState('RTG');
    const [bannerCurrentPage, setBannerCurrentPage] = useState('RTG');
    const [searchQuery, setSearchQuery] = useState("");

    const handleFilterChange = (filter) => {
        setCurrentFilter(filter);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>RTG Philippines X SG8 Casino | Best Online Slots 2024</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Play the best online slots of 2024 with RTG Philippines at SG8 Casino. Enjoy high-quality slots and exciting gameplay. Join now and win big!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <MainSlider 
                        sliderCurrentPage={sliderCurrentPage} 
                        bannerCurrentPage={bannerCurrentPage} 
                    />
                    <SlotProviderList initialProvider="mannaplay" />
                    <div className="slot-filter-search-wrapper">
                        <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                        <div className="filter-slots">
                            <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={rtgFilterOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <GameList provider="mannaplay" filter={currentFilter} searchQuery={searchQuery} />
        </>
    );
}

export default MannaPlay;
